@use "sass:list";
// @import "~@primer/css/layout/index.scss";
// @import "~@primer/css/base/index.scss";

@function _fr($repate) {
    $prefixes: ();

    @for $x from 1 through $repate {
        $prefixes: list.append($prefixes, 1fr);
    }

    @return $prefixes;
}


// This map in the form (breakpoint: variant) is used to iterate over
// breakpoints and create both responsive and non-responsive classes in one
// loop:
$responsive-variants: (
  '': '',
  sm: '-sm',
  md: '-md',
  lg: '-lg',
  xl: '-xl',
) !default;

// Breakpoint widths
$width-xs: 0 !default;
// Small screen / phone
$width-sm: 544px !default;
// Medium screen / tablet
$width-md: 768px !default;
// Large screen / desktop (980 + (16 * 2)) <= container + gutters
$width-lg: 1012px !default;
// Extra large screen / wide desktop
$width-xl: 1280px !default;
// Breakpoints in the form (name: length)
$breakpoints: (
  sm: $width-sm,
  md: $width-md,
  lg: $width-lg,
  xl: $width-xl
) !default;


@mixin breakpoint($breakpoint) {
    @if $breakpoint == '' {
      @content;
    }
  
    @else {
      // Retrieves the value from the key
      $value: map-get($breakpoints, $breakpoint);
  
      // If the key exists in the map
      @if $value != null {
        // Prints a media query based on the value
        @media (min-width: $value) {
          @content;
        }
      }
  
      // If the key doesn't exist in the map
      @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
          + 'Please make sure it is defined in `$breakpoints` map.';
      }
    }
  }

@each $breakpoint,$variant in $responsive-variants {
    @include breakpoint($breakpoint) {
        .d#{$variant}-grid {
            display: grid !important;

        }

        .d#{$variant}-grid>.flex#{$variant}-self-auto {
            align-self: auto !important;
        }

        .d#{$variant}-grid>.grid#{$variant}-self-start {
            align-self: start !important;
        }

        .d#{$variant}-grid>.grid#{$variant}-self-end {
            align-self: end !important;
        }

        .d#{$variant}-grid>.grid#{$variant}-self-center {
            align-self: center !important;
        }

        .d#{$variant}-grid>.grid#{$variant}-self-baseline {
            align-self: baseline !important;
        }

        .d#{$variant}-grid>.grid#{$variant}-self-stretch {
            align-self: stretch !important;
        }
    }
}

@each $breakpoint,
$variant in $responsive-variants {
    @include breakpoint($breakpoint) {
        @for $x from 1 through 12 {
            .grid#{$variant}-gap-#{$x} {
                grid-gap: $x*4px;
            }
            .grid#{$variant}-column-gap-#{$x} {
                column-gap: $x*4px;
            }
            .grid#{$variant}-row-gap-#{$x} {
                row-gap: $x*4px;
            }
            .grid#{$variant}-columns-#{$x} {
                grid-template-columns: _fr($x) !important;
            }

            .grid#{$variant}-rows-#{$x} {
                grid-template-rows: _fr($x) !important;
            }


            .grid#{$variant}-column-start-#{$x} {
                grid-column-start: $x;
            }

            .grid#{$variant}-column-end-#{$x} {
                grid-column-end: $x;
            }

            .grid#{$variant}-column-end-span#{$x} {
                grid-column-end: span $x;
            }

            .grid#{$variant}-row-start-#{$x} {
                grid-row-start: $x;
            }

            .grid#{$variant}-row-end-#{$x} {
                grid-row-end: $x;
            }

            .grid#{$variant}-row-end-span#{$x} {
                grid-row-end: span $x;
            }
        }
    }
}