body {
  height: 100% !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


details[open] summary span.ActionList-item-action.ActionList-item-visual--leading svg {
  transform: rotate(90deg);
  transition: transform 100ms ease 0s, fill 50ms ease 0s !important;
}

:root {

  --color-bg-default: #fff;

  /* --color-fg-default: #515a6e; */
  --color-fg-default: #333;
  --color-fg-muted: #666;

  --color-fg-subtle: #aaaaaa;

  --color-attention-fg: #f05000;
  --color-accent-fg: #417dc9;
  --color-accent-emphasis: #215da9;

  --color-border-default: #ccc;


  --color-background-medium: #f9f8f8;
  --color-background-strong: #f5f3f3;
  --color-canvas-inset: #f3f3f3;
}

[data-theme=default] {
  --color-bg-default: #fff;
  --color-canvas-default: #fff;
  --color-border-default: #d0d7de69;
  --color-accent-fg: rgb(0, 127, 127);
  --color-btn-primary-bg: rgb(0, 127, 127);

  --color-accent-emphasis: rgb(0, 127, 127);

  --color-fg-muted: #a1a1a1;
  --color-fg-accent: var(--color-accent-fg);

  --color-bg-emphasis: #4f5671;
  --color-bg-emphasis-plus: #333;
  --color-danger-emphasis: #9f1326;
  --color-danger-fg: #9f1326;
  --color-neutral-emphasis-plus: var(--color-bg-emphasis-plus);

  --color-btn-outline-text: var(--color-accent-fg);
  --color-btn-outline-hover-bg: var(--color-accent-emphasis);
  --color-btn-bg: var(--color-bg-default);
  --color-background-medium: #f9f8f8;
  --color-background-strong: #f5f3f3;
  --color-fg-on-emphasis: #ffffff;
}

[data-theme=green] {
  --color-bg-default: #fff;
  --color-canvas-default: #fff;
  --color-border-default: #d0d7de69;
  --color-accent-fg: rgb(0, 128, 0);
  --color-btn-primary-bg: rgb(0, 128, 0);

  --color-accent-emphasis: rgb(0, 128, 0);

  --color-fg-muted: #a1a1a1;
  --color-fg-accent: var(--color-accent-fg);

  --color-bg-emphasis: #4f5671;
  --color-bg-emphasis-plus: #333;
  --color-danger-emphasis: #9f1326;
  --color-danger-fg: #9f1326;
  --color-neutral-emphasis-plus: var(--color-bg-emphasis-plus);

  --color-btn-outline-text: var(--color-accent-fg);
  --color-btn-outline-hover-bg: var(--color-accent-emphasis);
  --color-btn-bg: var(--color-bg-default);
  --color-background-medium: #f9f8f8;
  --color-background-strong: #f5f3f3;
}

[data-theme=blue] {
  --color-bg-default: #fff;
  --color-canvas-default: #fff;
  --color-border-default: #d0d7de69;
  --color-accent-fg: #417dc9;

  --color-accent-emphasis: #04497e;
  --color-btn-primary-bg: var(--color-accent-emphasis);

  --color-fg-muted: #a1a1a1;
  --color-fg-accent: var(--color-accent-fg);

  --color-bg-emphasis: #4f5671;
  --color-bg-emphasis-plus: #333;
  --color-danger-emphasis: #9f1326;
  --color-danger-fg: #9f1326;
  --color-neutral-emphasis-plus: var(--color-bg-emphasis-plus);

  --color-btn-outline-text: var(--color-accent-fg);
  --color-btn-outline-hover-bg: var(--color-accent-emphasis);
  --color-btn-bg: var(--color-bg-default);
  --color-background-medium: #f9f8f8;
  --color-background-strong: #f5f3f3;
}

[data-theme=red] {
  --color-bg-default: #fff;
  --color-canvas-default: #fff;
  --color-border-default: #d0d7de69;
  --color-accent-fg: #d51c2dbf;
  --color-accent-emphasis: #D51C2D;

  --color-btn-primary-bg: var(--color-accent-emphasis);


  --color-fg-muted: #a1a1a1;
  --color-fg-accent: var(--color-accent-fg);

  --color-bg-emphasis: #4f5671;
  --color-bg-emphasis-plus: #333;
  --color-danger-emphasis: #9f1326;
  --color-danger-fg: #9f1326;
  --color-neutral-emphasis-plus: var(--color-bg-emphasis-plus);

  --color-btn-outline-text: var(--color-accent-fg);
  --color-btn-outline-hover-bg: var(--color-accent-emphasis);
  --color-btn-bg: var(--color-bg-default);
  --color-background-medium: #f9f8f8;
  --color-background-strong: #f5f3f3;
}

[data-theme=orange] {
  --color-bg-default: #fff;
  --color-canvas-default: #fff;
  --color-border-default: #d0d7de69;
  --color-accent-fg: #E16F04bf;
  --color-accent-emphasis: #E16F04;

  --color-btn-primary-bg: var(--color-accent-emphasis);


  --color-fg-muted: #a1a1a1;
  --color-fg-accent: var(--color-accent-fg);

  --color-bg-emphasis: #4f5671;
  --color-bg-emphasis-plus: #333;
  --color-danger-emphasis: #9f1326;
  --color-danger-fg: #9f1326;
  --color-neutral-emphasis-plus: var(--color-bg-emphasis-plus);

  --color-btn-outline-text: var(--color-accent-fg);
  --color-btn-outline-hover-bg: var(--color-accent-emphasis);
  --color-btn-bg: var(--color-bg-default);
  --color-background-medium: #f9f8f8;
  --color-background-strong: #f5f3f3;
}

[data-theme=admin] {
  --color-btn-primary-bg:var(--color-done-emphasis);
  /* --color-accent-fg:var(--color-fg-on-emphasis); */
  --color-accent-emphasis: var(--color-done-emphasis);
  /* --spacer: 8px;
  --spacer-0: 0;                  */
  /* --spacer-1: calc( (var(--spacer)) * 0.5);     
  --spacer-2: var(--spacer);           
  --spacer-3: calc( (var(--spacer)) * 2);      
  --spacer-4: calc( (var(--spacer)) * 3);       
  --spacer-5: calc( (var(--spacer)) * 4);       
  --spacer-6: calc( (var(--spacer)) * 5);       */
  /* --color-action-list-item-inline-divider:#9e9e9e; */
}

.color-bg-default {
  background-color: #fff !important;
}

.breadcrumb-item a {
  color: var(--color-fg-default)
}

.breadcrumb-item-selected a {
  color: var(--color-fg-accent)
}

.breadcrumb-item {
  margin-left: 0em;
}

@-webkit-keyframes animation-shimmering {
  0% {
    background-position: -300px 0
  }

  100% {
    background-position: 800px 0
  }
}

@keyframes animation-shimmering {
  0% {
    background-position: -300px 0
  }

  100% {
    background-position: 800px 0
  }
}

.PotGridBodyPlaceholder-itemRow {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  height: 34px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: visible;
  padding: 0 32px;
  position: relative;
  transition: box-shadow 0s ease-in;
  white-space: nowrap;
}

.PotGridBodyPlaceholder-circleCheck {
  -webkit-animation: animation-shimmering 1.5s linear infinite;
  animation: animation-shimmering 1.5s linear infinite;
  background-color: var(--color-background-medium);
  background-image: linear-gradient(-90deg, var(--color-background-medium) 0%, var(--color-background-strong) 50%, var(--color-background-medium) 100%);
  background-position: -300px 0;
  background-repeat: no-repeat;
  background-size: 300px 100%;
  background-color: var(--color-background-medium);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  margin-right: 8px;
}

.PotGridBodyPlaceholder-itemRowLeft {
  align-items: center;
  display: flex;
  flex: 1 1000 auto;
  height: 100%;
  justify-content: flex-start;
  min-width: 1px;
  position: relative;
  margin-left: 0;
}

.PotGridBodyPlaceholder-taskContent {
  -webkit-animation: animation-shimmering 1.5s linear infinite;
  animation: animation-shimmering 1.5s linear infinite;
  background-color: var(--color-background-medium);
  background-image: linear-gradient(-90deg, var(--color-background-medium) 0%, var(--color-background-strong) 50%, var(--color-background-medium) 100%);
  background-position: -300px 0;
  background-repeat: no-repeat;
  background-size: 300px 100%;
  border-radius: 4px;
  height: 12px;
  width: 300px;
}



.euiTable {
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.71429rem;
  font-feature-settings: "calt"1, "kern"1, "liga"1, "tnum"1;
  width: 100%;
  table-layout: fixed;
  border: none;
  border-collapse: collapse;
  background-color: #fff;
}

.euiTableFooterCell,
.euiTableHeaderCell {
  vertical-align: middle;
  border-top: 1px solid #D3DAE6;
  border-bottom: 1px solid #D3DAE6;
  font-weight: inherit;
  text-align: left;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word;
  color: #1a1c21;
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.71429rem;
  font-weight: 700;
  font-weight: 500;
  border-top: none;
}

.euiTableHeaderCell,
.euiTableFooterCell,
.euiTableCellContent--truncateText {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
}

.euiTableCellContent {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 8px;
}

.euiTableRowCell {
  vertical-align: middle;
  border-top: 1px solid #D3DAE6;
  border-bottom: 1px solid #D3DAE6;
  font-weight: inherit;
  text-align: left;
  color: #343741;
}

.euiTableCellContent {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 8px;
}

.euiTableCellContent--overflowingContent {
  overflow: visible;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;
}

.euiTableCellContent__text {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word;
  min-width: 0;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1200px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important;
  }
}

.SideNav-subItem[aria-selected=true] {
  font-weight: 500;
  color: #f05000;
}

.SideNav-item {
  background: #6a97d2;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.SideNav-item:hover {
  text-decoration: none;
  background-color: #76a4e0;
  outline: none;
}


@media only screen and (min-width: 540px) {
  .euiTableHeaderMobile{
    display: none;
  }
}

.box-shadow {
  background-color: #eff0f1 !important;
  border: 0;
  transition: box-shadow 150ms linear;
  border-radius: 2px;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 16%), 0 0px 3px 0 rgb(0 0 0 / 12%);
}

.box-shadow:hover, .box-shadow:focus {
  background-color: #ffffff !important;
}


summary:disabled, summary.disabled, summary[aria-disabled=true] {
  color: var(--color-primer-fg-disabled);
  background-color: var(--color-btn-bg);
  border-color: var(--color-btn-border);
  cursor: default;

}

summary[aria-disabled=true].box-shadow:hover, summary[aria-disabled=true].box-shadow:focus {
  background-color: unset !important;
}


.ActionList-item[aria-checked='true'],
.ActionList-item[aria-selected='true'] {
  background: var(--color-action-list-item-default-active-bg);
}


::-webkit-scrollbar {
  width: 8px;  
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  

  background: rgba(0, 0, 0, 0.2);
}

#knowledge-root .UnderlineNav-item {
  /* line-height: 24px; */
}

#knowledge-root  .UnderlineNav {
  min-height: 58px;
}

#knowledge-root  .SideNav-item.open {
  background: #f1f9ff;
  color: #417dc9;
  border: 1px solid #b8e0ff;
}

/* footer */
#footer_div{ width:100%; background:#f9f9f9; border-top:1px solid #e9e9e9; margin-top:25px; padding:15px 0; color:#666;}
.footer_tab{  margin:0 auto 20px auto;}
.footer_tab ul{ list-style:none; }
.footer_tab ul li{ background-position:0 -207px; padding-left:15px; }
.footer_tab th{ font-size:12px; font-weight:bold; color:#8d8d8d; text-align:left; padding-bottom:10px; padding-left:30px;}
.footer_tab td{  vertical-align:top;border-right:1px dotted #ddd; padding:0 35px 0 20px;}
.footer_tab td.language_td{ border-right:none; padding-right:0;}
.footer_tab td.service_td{ padding-left:0;}
#footer_div p>a{ color:#666;}
#foot_text{ text-align:center; line-height:200%;}
#foot_text a{ margin:0 10px;}
#foot_text span{ background-position:0 -108px; display:block; width:37px; height:50px; margin:0 auto;}
.watermark { color: #999;}

.cursor-pointer {
  cursor: pointer;
}

.red.tabnav {
  margin-top: 0;
  margin-bottom: 16px;
  border-bottom: 1px solid  #b71515;
}
.red.tabnav .tabnav-tab {
  line-height: 10px;
}
.red.tabnav .tabnav-tab.selected, .tabnav-tab[aria-selected=true], .tabnav-tab[aria-current]:not([aria-current=false]) {
  color: #fff;
  background-color: #b71515;
  border-color: #b71515;
  border-radius: 6px 6px 0 0;
}

.wfhkstyle a{
  color:rgb(255, 108, 0) !important;
}

.left.dropdown-menu-ne  {
  right:0 !important;
  left: unset;
}

.left.dropdown-menu-ne::before {
  right:9px;
  left: unset;
}

.left.dropdown-menu-ne::after {
  right:9px;
  left: unset;
}